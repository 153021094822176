<template>
  <div class="PerformancePlan flex justify-center">
    <div>
      <div
        class="
          PerformancePlan-refuse
          bg-white
          border-round-8
          margin-top-lg margin-bottom-sm
        "
        v-if="planState === '3'"
      >
        <span style="color: #ff6d6d">拒绝理由:</span> {{ store.state.remark }}
        <i class="icon status-rejected status" />
      </div>
      <div
        class="content-box margin-bottom-lg box-ing"
        :style="{ height: planState === '3' ? 'calc(100% - 180px)' : '100%' }"
      >
        <div
          class="
            border-round-12
            bg-white
            margin-top
            padding-lr-xl padding-tb-lg
          "
          v-loading="isLoading"
        >
          <el-form :model="planList" label-width="140px" ref="planRef">
            <div
              v-for="(item, index) in planList"
              :key="index"
              class="PerformancePlan-form"
            >
              <div class="flex justify-between">
                <div>目标({{ index + 1 }})</div>
                <i
                  v-if="index !== 0"
                  class="icon delete cursor-pt"
                  @click="delListItem(index, item)"
                />
              </div>
              <el-form-item
                :prop="index + '.corePerformanceIndicators'"
                :rules="[
                  {
                    required: true,
                    message: '核心工作指标不能为空',
                    trigger: 'blur',
                  },
                ]"
                label="核心工作指标 :"
              >
                <el-input
                  type="textarea"
                  placeholder="请输入核心工作指标"
                  v-model="item.corePerformanceIndicators"
                  maxlength="200"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                :prop="index + '.indicatorsStandardValue'"
                :rules="[
                  {
                    required: true,
                    message: '指标标准值不能为空',
                    trigger: 'blur',
                  },
                ]"
                label="指标标准值 :"
              >
                <el-input
                  type="textarea"
                  placeholder="请输入指标标准值 "
                  v-model="item.indicatorsStandardValue"
                  maxlength="200"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                :prop="index + '.standardEvaluation'"
                :rules="[
                  {
                    required: true,
                    message: '评分标准不能为空',
                    trigger: 'blur',
                  },
                ]"
                label="评分标准 :"
              >
                <el-input
                  type="textarea"
                  placeholder="请输入内容评分标准"
                  v-model="item.standardEvaluation"
                  maxlength="200"
                  show-word-limit
                />
              </el-form-item>
              <div class="flex justify-between">
                <el-form-item
                  :prop="index + '.planTime'"
                  :rules="[
                    {
                      required: true,
                      message: '计划用时不能为空',
                      trigger: 'blur',
                    },
                  ]"
                  label="计划用时(h) :"
                >
                  <el-input-number
                    placeholder="请输入计划用时"
                    v-model="item.planTime"
                    :min="0"
                    :max="200"
                    :precision="0"
                  />
                </el-form-item>
                <el-form-item
                  :prop="index + '.weight'"
                  :rules="[
                    {
                      required: true,
                      message: '权重不能为空',
                      trigger: 'blur',
                    },
                  ]"
                  label="权重(配分100) :"
                >
                  <el-input-number
                    placeholder="请输入权重"
                    v-model="item.weight"
                    :min="0"
                    :max="100"
                    :precision="0"
                  />
                </el-form-item>
                <el-form-item
                  :prop="index + '.departmentId'"
                  :rules="[
                    {
                      required: true,
                      message: '数据来源部门必选',
                      trigger: 'change',
                    },
                  ]"
                  label="数据来源部门 :"
                >
                  <el-cascader
                    v-model="item.departmentId"
                    :options="dataSourceList"
                    :show-all-levels="false"
                    placeholder="选择数据来源部门"
                    :props="cascaderProps"
                  />
                </el-form-item>
              </div>
            </div>
          </el-form>
          <el-button
            plain
            class="margin-top"
            icon="el-icon-plus"
            @click="addListItem"
            >增加目标</el-button
          >
        </div>
        <Flow :submit="submit" :isLoading="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { nextTick, onMounted, onUnmounted, ref, watchEffect } from 'vue'
import Flow from '@/components/Flow'
import {
  dataSource,
  selectMonthPlan,
  deleteItem,
  submitMonthPlan
} from '@/api/user'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'
import { useStore } from '@/vuex/store'
import { dpdata } from './department'

export default {
  name: 'PerformancePlan',
  components: {
    Flow
  },
  setup () {
    // ['8326161', '8333039', '125882168']
    const planRef = ref(null)
    const route = useRoute()
    const store = useStore()
    const planList = ref([])
    const dataSourceList = ref([])
    const planState = ref('1')
    const month = ref('')
    const isLoading = ref(false)
    const addListItem = () => {
      console.log('object :>> ', store.state.user, store.state.user.userid === '01665629352194')
      planList.value.push({
        // 核心工作指标s
        corePerformanceIndicators: '',
        // 指标标准值
        indicatorsStandardValue: '',
        // 评分标准
        standardEvaluation: '',
        // 计划用时
        planTime: undefined,
        // 权重
        weight: undefined,
        // 数据来源部门id
        // 针对严明账号做的特殊处理
        departmentId: store.state.user.userid === '01665629352194' ? ['8326161', '8333039', '125882168'] : [],
        monthPlanId: Number(route.query.id)
      })
    }
    const delListItem = (index, item) => {
      console.log(route.query.id)
      if (item.id) {
        deleteItem({
          itemId: item.id
        }).then((res) => {
          if (res.code === 200) {
            planList.value.splice(index, 1)
          }
        })
        return
      }
      planList.value.splice(index, 1)
    }
    // 提交
    const submit = () => {
      console.log('planList :>> ', planList)
      planRef.value.validate((valid) => {
        if (valid) {
          let sum = 0
          const monthPlanItemEntityList = []
          const { name, userid, position, departmentId } = store.state.user
          planList.value.forEach((item) => {
            sum += item.weight
            monthPlanItemEntityList.push({
              ...item,
              departmentId: item.departmentId[item.departmentId.length - 1],
              weight: item.weight.toString(),
              planTime: item.planTime.toString()
            })
          })
          if (sum !== 100) {
            return ElMessage.error('权重总分只能是100')
          }
          isLoading.value = true
          const monthPlanEntity = {
            id: Number(route.query.id),
            planState: 2,
            publishName: name,
            publishId: userid,
            position,
            month: month.value,
            departmentId: departmentId.split('/')[0]
          }
          console.log(monthPlanItemEntityList)
          submitMonthPlan({
            monthPlanEntity,
            monthPlanItemEntityList
          }).then((res) => {
            isLoading.value = false
            if (res.code === 200) {
              ElMessage.success('提交成功')
              store.action.updateIsSubmit(true)
            }
          })
        } else {
          nextTick(() => {
            const element = document.querySelector('.is-error')
            // // 第一种方法（包含动画效果）
            element.scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
        }
      })
    }

    // 获取数据来源
    const getDataSource = (e) => {
      dataSourceList.value = dpdata
      // dataSource({
      //   first: true
      // }).then((res) => {
      //   if (res.code === 200) {
      //     dataSourceList.value = res.data
      //   }
      // })
    }

    const cascaderProps = {
      value: 'id',
      label: 'name',
      checkStrictly: true,
      lazy: false
      // lazyLoad (node, resolve) {
      //   if (node.value) {
      //     dataSource({
      //       first: false,
      //       id: node.value
      //     }).then((res) => {
      //       if (res.code === 200) {
      //         const data = res.data.kvDeptList.length
      //           ? res.data.kvDeptList
      //           : []
      //         resolve(data)
      //       }
      //     })
      //   }
      // }
    }

    const getInfo = () => {
      isLoading.value = true
      selectMonthPlan({ id: route.query.id }).then((res) => {
        isLoading.value = false
        planList.value = []
        if (res.code === 200) {
          month.value = res.data.monthPlanEntity.month
          const { monthPlanItemEntityList } = res.data
          console.log('monthPlanItemEntityList :>> ', monthPlanItemEntityList)
          if (monthPlanItemEntityList.length > 0) {
            monthPlanItemEntityList.forEach((item) => {
              planList.value.push({
                ...item,
                weight: Number(item.weight),
                planTime: Number(item.planTime),
                departmentId: store.state.user.userid === '01665629352194' ? ['8326161', '8333039', '125882168'] : []
              })
            })
          } else {
            addListItem()
          }
        }
      })
    }

    onMounted(() => {
      getDataSource()
    })

    watchEffect(() => {
      if (route.query.planState) {
        getInfo()
        planState.value = route.query.planState
      }
    })
    onUnmounted(() => {
      // if (planState.value === '1') {
      //   exitSave()
      // }
    })
    return {
      planList,
      addListItem,
      delListItem,
      submit,
      planRef,
      dataSourceList,
      cascaderProps,
      planState,
      store,
      isLoading,
      month
    }
  }
}
</script>
<style lang="less">
.el-cascader-node {
  position: relative;
}

.PerformancePlan-form[data-v-0ec059dc] .el-form-item {
  margin: 30px 0;
}

//.el-radio{
//  position: absolute;
//  width:calc(100% - 30px);
//  height: 100%;
//  left: 20px;
//  top: 0;
//}
//.el-radio__inner{
//  display: none
//}</style>
<style scoped lang="less">
.PerformancePlan {
  height: calc(100% - 80px);
  overflow: hidden;

  >div {
    height: 100%;
  }

  &-refuse {
    padding: 12px 32px;
    position: relative;

    .status-rejected {
      top: -20px;
    }
  }

  &-form:not(:first-child) {
    margin-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 24px;
  }

  &-form::v-deep {
    label {
      width: 190px;
    }

    .el-form-item {
      margin-bottom: 8px;
    }

    .el-form-item__content {
      margin: 0 !important;
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      display: none !important;
    }
  }

  &-button {
    margin-top: 8px;
    flex-direction: row-reverse;
    background: #fff;
    border-radius: 2px;
    width: 100%;
    padding: 16px 32px;

    .el-button--small::v-deep {
      border-radius: 4px;
      background: #1890ff;
      color: #fff;
    }
  }

  .content-box {
    width: 960px;
    height: 100%;
    overflow: auto;

    .bg-white>.el-button--small::v-deep {
      padding: 8px 16px;
      color: #1890ff;
      border-color: #1890ff;
      border-radius: 8px;
      font-size: 16px;
    }
  }

  .el-input-number::v-deep {
    .el-input__inner {
      padding: 0 15px;
      text-align: left;
    }
  }
}
</style>
