export const dpdata = [
  {
    name: '品融控股集团有限公司',
    id: '8326161',
    children: [
      {
        name: '总裁办公室',
        id: '8333039',
        children: [
          {
            name: '总裁办',
            id: '8414471'
          },
          {
            name: '秘书组',
            id: '125774297'
          },
          {
            name: '外联组',
            id: '125882168'
          },
          {
            name: '审计组',
            id: '125922037'
          },
          {
            name: '外部组织',
            id: '28718024'
          }
        ]
      },
      {
        name: '人资行政中心',
        id: '63597585'
      },
      {
        name: '财务中心',
        id: '8332034',
        children: [{ name: '财务部', id: '51647009' }]
      },
      {
        name: '采购物流中心',
        id: '125794208'
      },
      {
        name: '迈道夫科技信息化建设中心',
        id: '141383190',
        children: [{ name: '开发A组', id: '490536785' }, { name: '开发B组', id: '490528873' }, { name: '其他组', id: '658827553' }]
      },
      {
        name: '迈道夫经营运营中心',
        id: '8414476',
        children: [{ name: '生产技术组', id: '125851123' }, { name: '东方区', id: '371877226' }, { name: '凯旋区', id: '125821156' }, { name: '丽水路大浒街店', id: '105318610' }, { name: '天目山（浙大）商务会所', id: '45646319' }, { name: '西城年华', id: '8405591' }, { name: '城西银泰店', id: '8405593' }, { name: '拱墅区政府店', id: '8405582' }, { name: '新天地店', id: '64281153' }, { name: '培训基地', id: '65523101' }]
      },
      {
        name: '和生合经营运营中心',
        id: '134766290',
        children: [{ name: '门店经营管理组', id: '125834172' }]
      }
    ]
  },
  {
    name: '浙江迈道夫科技有限公司',
    id: '141243025'
  },
  {
    name: '杭州和生合饮品有限公司',
    id: '84948976'
  },
  {
    name: '杭州六和合文创科技有限公司',
    id: '410835614',
    children: [{ name: '文创部', id: '448681987' }]
  },
  {
    name: '杭州迈道夫汽车服务有限公司',
    id: '8306606'
  }
]
